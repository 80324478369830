@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    background-color: white;
    text-decoration: none !important;
    font-family: 'Roboto Slab', serif !important;
}

.icon {
    color: #93b236;
    font-size: 3rem;
    margin-top: 6px;
}

a{
    color: #000 !important;
    text-decoration: none !important;
}
a:hover{
    color: #000;
    text-decoration: none;
}
h1, h2, h3, h4, h5, h6{
    font-family: 'Roboto Slab', serif;
}

.content-wrapper{
    margin-left: 180px;
}


/* jksegjsrkljg */

.side-navbar {
    width: 180px;
    height: 100%;
    position: fixed;
    /* margin-left: -300px; */
    background-color: #ffffff;
    transition: 0.3s linear;
    top: 80;
    z-index: 888;
    display: block;
}

.nav-link:active,
.nav-link:focus,
.nav-link:hover {
    background-color: #5d731e;
    color: #fff;
}

.nav-link{
    color: #000;
}

.nav-links:active,
.nav-links:focus,
.nav-links:hover {
    /* background-color: #5d731e; */
    color: #5d731e;
}

.nav-links{
    color: #000;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
}

.my-container {
    transition: 0.2s linear;
}

.active-nav {
    margin-left: 0;
}

/* for main section */
.active-cont {
    margin-left: 180px;
}

.lineSeprator{
    margin: 0 !important;
}

.marginTopData{
    margin-top: 1rem;
}

#menu-btn {
    background-color: #93b237;
    color: #fff;
    /* margin-left: -62px; */
    position: absolute;
    margin-top: 80px;
    z-index: 100;
    display: none;
    border-radius: 0;
}

.side-nav-open{
    display: none;
}
/* jgeskgeklje */



.text-success{
    color: #96c11c !important;
}

/* **** */
/* home */
/* **** */
.home{
    width: 100%;
    /* height: 15vh; */
    background-color: #212529;
    /* margin-left: 160px; */
    position: fixed;
    z-index: 999 ;
}
header ul{
    list-style-type: none;
    display: flex;
    margin: 0;
    font-weight: 100;
}
header ul li{
    margin: 10px;
    color: #fff;
}

nav{
    height: 80px;
    width: 100%;
    background-color: #ffffff;
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* border-radius: 6px; */
}
nav .logo{
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
nav .logo img{
    height: 60px;
    width: 160px;
}
nav ul{
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
}
nav ul li{
    text-transform: uppercase;
    height: 100%;
    width: 100%;
    transition: 0.6s;
}
nav ul li a{
    transition: 0.5s;
    font-weight: 500 !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    color: black;
}
.active-nav-top a{
    color: #fff;
}
nav ul li:hover, nav ul li:hover a{
    background-color: #5d731e;
    color: #fff;
}
.active-nav-top{
    background-color: #5d731e;
}
#offcanvasTop{
    height: 100% !important;
    width: 100% !important;
}

.content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #fff;
    text-align: center;
}

.content h1{
    font-weight: 700;
    letter-spacing: 2px;
    font-size: 52px;
}
.content h4{
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 400;
}

.btn-green{
    padding: 10px 24px;
    margin: 4px;
    border: none;
    background-color: #5d731e;
    color: #fff;
    font-weight: 500;
    transition: 0.6s;
}
.btn-green:hover{
    color: #5d731e;
    background-color: #fff;
}
.btn-white{
    padding: 10px 24px;
    margin: 4px;
    color: #5d731e;
    border: none;
    font-weight: 500;
    transition: 0.6s;
}
.btn-white:hover{
    color: #fff;
    background-color: #5d731e;
}

.home-2{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    /* top: 0; */
    z-index: -2;
    background-color: #000;
    padding-top: 80px;
}

.home-inner{
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    z-index: 1;
    background: none;
    width: 100%;
}

/* slick-slider */
.slider {
    position: absolute;
    top: 0;
    left: 0;
    /* z-index: 200; */
    width: 100%;
    opacity: 0.5;
}

/* .slick-arrow {
    position: absolute;
    top: 50%;
    width: 40px;
    height: 50px;
    line-height: 50px;
    margin-top: -25px;
    border: none;
    background: transparent;
    color: #fff;
    font-family: monospace;
    font-size: 5rem;
    z-index: 300;
    outline: none;
}

.slick-prev {
    left: -50px;
    text-align: left;
}

.slick-next {
    right: -50px;
    text-align: right;
} */

.item{
    position: relative;
    display: table;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.item.slick-slide {
    width: 455px;
    /* height: 600px !important; */
    height: 100vh;
    width: 100%;
    transition: transform .4s;
    position: relative;
}

.slick-slide img{
    z-index: -5;
    width: 100%;
}

.slick-slide:after {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform .4s;
}

/* .slick-dots li button::before{
    content: none;
}

.slick-dots li button{
    display: block;
    width: 10px;
    height: 10px;
    line-height: 10px;
    border-radius: 50%;
    background: black;
    border: 2px solid #000000;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    cursor: pointer;
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.slick-dots li:hover button, .slick-dots li.slick-active button{
    background: transparent;
    background-color: transparent;
    border: 2px solid #000000;
    border-radius: 50%;
} */


/* slick-slider */


.close-icon{
    display: none;
}

.brand-name{
    display: none;
}

.appoinment .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    opacity: 1; /* Firefox */
}  
.appoinment .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
}  
.appoinment .form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff;
}

@media screen and (max-width: 992px) {
    header{
        display: none;
    }

    .line {
        width: 25px;
        height: 2px;
        background: rgb(0, 0, 0);
        margin: 5px;
    }

    nav {
        position: relative;
        padding-top: 8px !important;
        height: 60px !important;
        width: 100% !important;
    }

    .logo-mobile{
        display: block !important;
    }

    nav .logo img {
        height: 50px;
        width: 130px;
    }

    .content{
        height: 100%;
    }

    .hamburger {
        position: absolute;
        cursor: pointer;
        right: 5%;
        top: 50%;
        transform: translate(-5%, -50%);
        z-index: 2;
    }

    .brand-name{
        display: block;
    }

    .search-icon{
        display: block;
    }

    .close-icon{
        display: block;
    }

    .btn-close{
        margin-top: 21px;

    }

    .custom-navbar-nav{
        margin: 6rem 6rem 3rem 6rem;
    }

    .item.slick-slide{
        height: 100vh;
    }
    .home-2{
        height: 100vh;
        padding-top: 0;
    }
    .content h1{
        font-size: 32px;
    }
    .content h4{
        font-size: 20px;
    }
    .side-navbar {
        top: 60;
        margin-left: -300px;
    }
    #menu-btn{
        display: block;
    }
    .content-wrapper{
        margin-left: 0;
    }
    .side-nav-close{
        display: none;
    }
    .side-nav-open{
        display: block;
    }
}

/* **** */
/* home */
/* **** */

.section-title p {
    margin-bottom: 0px;
    max-width: 50rem;
    text-align: center;
    margin: auto;
}

.card{
    overflow: hidden;
}

.icon{
    text-align: left;
    float: left;
}

.icon i {
    font-size: 3rem;
    color: #93b236;
    margin-top: 6px;
}
.about-info{
    margin-left: 60px;
}


/* ******************* */
/* achievement-counter */
/* ******************* */
.achievement-counter{   
    background: url('../media/background-effect3.jpg');
    background-size: cover;
    z-index: -2;
}
.counter{
    text-align: center;
}
.counter-icon i {
    color: #93b236;
    font-size: 4.875rem;
    line-height: 89px;
    margin-bottom: 15px;
}
.counter-text h2 {
    font-size: 2.5rem;
    font-weight: 400;
    color: #fff;
    margin-bottom: 0px;
}
.counter-text p {
    font-size: 1rem;
    color: #fff;
    font-weight: 700;
    margin-bottom: 0px;
    text-transform: uppercase;
}
.dark-bg{
    background-color: rgba(42,42,42,0.85);
    z-index: -1;    
    padding-top: 50px;
    padding-bottom: 50px; 
}
/* ******************* */
/* achievement-counter */
/* ******************* */


/* ******** */
/* our-work */
/* ******** */
.home-work{
    height: 40vh;
    width: 100%;
}

.home-work-2{
    height: 40vh;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    background-color: #000;
}

.work-home{
    /* background: url('../media/banner_1.jpg'); */
    background-size: cover;
    z-index: -2;
}
.dark-bg{
    background-color: rgba(42,42,42,0.85);
    z-index: -1;    
    padding-top: 50px;
    padding-bottom: 50px; 
}
.work-home .content{
    height: auto;
}
.work{
    background: #f7f7f7;
}
/* ******** */
/* our-work */
/* ******** */


/* **** */
/* card */
/* **** */
.services .card .card-body{
    background-color: #f7f7f7;
}
.card{
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%);
    overflow: hidden;
    transition: all 300ms ease;
}
.card .card-img-top{
    overflow: hidden;
}
.card .card-img-top img{
    transition: all 300ms ease;
}
.card .card-img-top a img:hover{
    transform: scale(1.1);
}
.card .card-body h5 a{
    text-decoration: none;
    border-bottom: 1px solid transparent;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}
.card .card-body h5 a:hover{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
/* **** */
/* card */
/* **** */

/* ************** */
/* service-2 card */
/* ************** */
.services-2 .card .card-body{
    background-color: #f7f7f7;
}
.hover-img, .hover-bottom{
    position: relative;
    z-index: 0;
}
.hover-info {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    background-color: rgba(147, 178, 54, 0.75);
    transition: opacity .6s;
}
.hover-bottom:after {
    content: '';
    background-color: #93b236;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity .6s;
}
.hover-info a{
    transition: all .3s;
}
.hover-bottom p{
    z-index: 0;
    position: relative;
    transition: all .3s;
}
.hover-bottom a{
    transition: all .3s;
}
.hover-wrap:hover .hover-bottom:after, .hover-wrap:hover .hover-info {
    opacity: 1;
    cursor: pointer;
}
.hover-wrap:hover .hover-bottom p{
    color: #fff !important;
}
.hover-wrap:hover .hover-bottom a {
    color: #fff;
    transition: all .3s !important;
}
.hover-wrap:hover .hover-bottom a:hover, .hover-wrap:hover i:hover {
    color: #5d731e;
}
/* ************** */
/* service-2 card */
/* ************** */

/* ********* */
/* our-team */
/* ********* */
.our-team .hover-img, .our-team .hover-bottom {
    position: relative;
    z-index: 0;
}
.our-team .team-box .hover-img {
    padding: 8px;
    border-radius: 100%;
    background-color: #efefed;
}
.our-team .team-box .hover-img img {
    width: 100%;
    height: auto;
}
.our-team .hover-wrap:hover .hover-img {
    background-color: #93b236;
}
.our-team .hover-wrap:hover .hover-bottom:after, .our-team .hover-wrap:hover .hover-info {
    opacity: 1;
    cursor: pointer;
}
.our-team .hover-info {
    border-radius: 100%;
    position: absolute;
    top: 7.5%;
    left: 7.5%;
    height: 85%;
    width: 85%;
}
.our-team .team-text {
    text-align: center;
    background-color: #ededed;
    position: relative;
    padding: 13px 10px;
    transition: opacity .6s;
    opacity: 1;
}
.our-team .tri {
    height: 20px;
    width: 40px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #efefed;
    border-top: 0px solid transparent;
    transition: opacity .6s;
    opacity: 1;
}
.our-team .hover-wrap:hover .tri {
    border-bottom-color: #93b236;
    opacity: 1;
}

.our-team .hover-bottom:after {
    content: '';
    background-color: #93b236;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity .6s;
}
.our-team .hover-wrap:hover .hover-bottom:after, .our-team .hover-wrap:hover .hover-info {
    opacity: 1;
    cursor: pointer;
}
/* ********* */
/* our-team */
/* ********* */

/* ************ */
/* our-projects */
/* ************ */
.our-projects.grey-bg {
    background-color: #7a7a7a00;
}

.our-projects {
    position: relative;
}

.dark-background{
    position: relative;
}
.dark-background:before {
    content: "";
    background-color: rgba(42,42,42,0.85);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}
.dark-background:after {
    content: "";
    background: url(https://www.webfulcreations.com/envato/green-garden/assets/images/help/background-effect2.jpg);
    background-size: cover;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -2;
}


.owl-carousel .owl-nav .prev, .owl-carousel .owl-nav .next, .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.our-projects .prev, .our-projects .next {
    border: 2px solid;
    color: #fff;
    border-radius: 100%;
    line-height: 31px;
    width: 35px;
    height: 35px;
    display: table;
    text-align: center;
    position: absolute;
    top: 50%;
    background: transparent;
}
.our-projects .prev {
    left: -60px;
}
.our-projects .next {
    right: -60px;
}
.our-projects .prev:hover,.our-projects .next:hover {
    background-color: #93b236;
    color: #FFF;
    border-color: #93b236;
}

@media screen and (max-width: 39.9375em){
.project {
    overflow: hidden;
    max-width: 258px;
}
}

@media screen and (min-width: 40em) and (max-width: 63.9375em){
.project {
    overflow: hidden;
    max-width: 532px;
}
}
/* ************ */
/* our-projects */
/* ************ */


/* ****** */
/* footer */
/* ****** */
.footer-top{
    background-color: #283218;
}
.footer-top .contact-us h2:after, .footer-top .useful-links h2:after {
    content: "";
    /* left: 0px; */
    /* top: 48px; */
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
    padding: 0.5px;
    /* position: absolute; */
    border: 1px solid #a3972e;
    width: 54px;
    display: block;
}
.useful-links ul li a{
    color: #fff;
}
.border-center{
    border-right: 1px solid #FFF;
    margin-left: 0px;
}

.contact-us .footer-detail li i {
    /* margin-right: 1.25rem; */
    font-size: 1.125rem;
    /* float: left; */
    color: #FFF;
}
.contact-us .footer-detail li h6, .contact-us .footer-detail li h5 {
    /* margin-left: 2.312rem; */
    font-weight: 400;
}
/* ****** */
/* footer */
/* ****** */


#top {
    position: fixed;
    right: 20px;
    bottom: 20px;
    color: #FFF;
    background-color: #93b237;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    font-size: 19px;
    line-height: 30px;
    box-shadow: 0px 1px 1px #ededed;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.news {
    position: fixed;
    left: 0px;
    color: #FFF;
    background-color: #93b237;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    font-size: 19px;
    line-height: 30px;
    box-shadow: 0px 1px 1px #ededed;
    z-index: 999;
    align-items: center;
    justify-content: center;
}
#news1{
    top: 300px;
}
#news2{
    top: 400px;
}
#news3{
    top: 500px;
}
#news4{
    top: 600px;
}

.sideinfo{
    position: fixed;
    top: 250px;
    /* right: -163px; */
    right: -100%;
    /* color: #FFF;
    background-color: #93b237; */
    width: 10%;
    height: 500px;
    /* border-radius: 5px; */
    /* text-align: center;
    font-size: 19px;
    line-height: 30px; */
    /* box-shadow: 0px 1px 1px #ededed; */
    z-index: 999;
    /* align-items: center;
    justify-content: center; */
    transition: all 0.6s;
}
.leftarrow{
    right: 0;
    color: #FFF;
    background-color: #93b237;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    text-align: center;
    font-size: 19px;
    line-height: 30px;
    box-shadow: 0px 1px 1px #ededed;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sidemenu{
    color: #FFF;
    background-color: #93b237;
    width: 100%;
    height: 500px;
    border-radius: 5px;
    text-align: center;
    font-size: 19px;
    line-height: 30px;
    box-shadow: 0px 1px 1px #ededed;
    z-index: 999;
    align-items: center;
    justify-content: center;
}
.sideinfo:hover {
    right: 0px;
}




#musli-social-links {
    list-style-type: none;
    z-index: 1000;
    position: fixed;
    right: 0;
    top: 10%;
}

#musli-social-links {
    top: 50%;
}

#musli-social-links li {
    margin-bottom: 10px;
}
#musli-social-links > li {
    height: 40px;
}
#musli-social-links > li {
    display: block;
    position: relative;
    background: #93b237;
    margin-bottom: 5px;
    right: -100%;
    height: 30px;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}
#musli-social-links > li:hover {
    right: 0;
}
#musli-social-links > li > a {
    line-height: 30px;
}
#musli-social-links > li > a {
    display: block;
    /* font-size: 20px; */
    white-space: nowrap;
    /* font-family: 'Racing Sans One', cursive; */
    font-family: "Kalam", sans-serif;
    color: #000;
    text-decoration: none;
    line-height: 30px;
    padding: 0 15px;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

#musli-social-links a, #musli-social-links a:focus, #musli-social-links a:active {
    outline: 0!important;
}
#musli-social-links li a i {
    font-size: 15px;
}
#musli-social-links li > a > i {
    width: 40px;
    height: 40px;
    line-height: 40px;
}
#musli-social-links > li > a > i {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 20px;
    position: absolute;
    right: 100%;
    color: #fff;
    background: #93b237;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
}

.carousel .slider-wrapper {
    height: 100vh !important;
}